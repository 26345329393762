import { rgba } from "polished";
import { breakpoints } from "./breakpoints";

const defaultColors = {
  primary: "#28AFAC",
  secondary: "#50798b", // Color picked from the gradient
  white: "#ffffff",
  dark: "#0F1C29",
  black: "#000000",
  yellow: "#fedc5a",
  ash: "#000000",
  green: "#77bf41",
  lightGreen: "#36D1CF",
  info: "#0e567c",
  grey: "#F8FAFD",
  red: "#FE5F55",
  blue: "#4D8DCC",
  orangeSecond: "#F18B2D",
  purple: "#7E3BA7",
  purpleSecond: "#BA57CE",
  darkGrey: "#353535",
  blue5: "#F4F7F9",
};

const colors = {
  primary: defaultColors.primary,
  secondary: defaultColors.secondary,
  light: defaultColors.white,
  lightShade: rgba(defaultColors.white, 0.75),
  dark: defaultColors.dark,
  darkShade: rgba(defaultColors.dark, 0.75),
  ash: defaultColors.ash,
  bg: defaultColors.white,
  greyBg: defaultColors.grey,
  border: rgba(defaultColors.ash, 0.115),
  shadow: rgba(defaultColors.ash, 0.175),
  heading: defaultColors.dark,
  text: defaultColors.dark,
  warning: defaultColors.yellow,
  success: defaultColors.green,
  info: defaultColors.info,
  red: defaultColors.red,
  blue: defaultColors.blue,
  lightGreen: defaultColors.lightGreen,
  orangeSecond: defaultColors.orangeSecond,
  purple: defaultColors.purple,
  purpleSecond: defaultColors.purpleSecond,
  darkGreyOpacity50: rgba(defaultColors.darkGrey, 0.5),
  black: defaultColors.black,
  blue5: defaultColors.blue5,
  modes: {
    dark: {
      primary: defaultColors.primary,
      secondary: defaultColors.secondary,
      light: defaultColors.white,
      lightShade: rgba(defaultColors.white, 0.75),
      dark: defaultColors.dark,
      darkShade: rgba(defaultColors.dark, 0.75),
      ash: defaultColors.ash,
      bg: defaultColors.dark,
      border: rgba(defaultColors.ash, 0.115),
      shadow: rgba(defaultColors.ash, 0.175),
      heading: defaultColors.white,
      text: rgba(defaultColors.white, 0.75),
      warning: defaultColors.yellow,
      success: defaultColors.green,
      lightGreen: defaultColors.lightGreen,
      orangeSecond: defaultColors.orangeSecond,
      purpleSecond: defaultColors.purpleSecond,
      info: defaultColors.info,
    },
  },
};

const theme = {
  initialColorModeName: "light",
  colors: colors,
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  breakpoints: [
    `${breakpoints.sm}px`,
    `${breakpoints.md}px`,
    `${breakpoints.lg}px`,
    `${breakpoints.xl}px`,
  ],
};

export default theme;
