import React from "react";
import { Link } from "gatsby-plugin-intl";

import flopLogoPng from "../../assets/image/flop/flop-website-logo.png";
import flopLogoDarkPng from "../../assets/image/flop/flop-website-logo-dark.png";

const Logo = ({ white, height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      {white ? (
        <img
          alt="Logo First Land of Poker"
          className="img-fluid"
          src={flopLogoPng}
        />
      ) : (
        <img
          alt="Logo First land of poker"
          className="img-fluid"
          src={flopLogoDarkPng}
        />
      )}
    </Link>
  );
};

export default Logo;
