import React from "react";
import styled from "styled-components";
import {Col, Row} from "react-bootstrap";
import {Link} from "gatsby-plugin-intl";

import {Box, Text, Title} from "../Core";

import downloadPng from "../../assets/image/flop/icons/download.png";
import giftPng from "../../assets/image/flop/icons/gift.png";
import offerPng from "../../assets/image/flop/icons/offer.png";
import tournamentPng from "../../assets/image/flop/icons/tournament.png";

const TitleStyled = styled(Title)`
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 16px;
`;

const UlStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    line-height: 2.25;
    a {
      color: ${({theme, color}) => theme.colors[color]} !important;
      &:hover {
        text-decoration: none;
        color: ${({theme, color}) => theme.colors.primary} !important;
      }
    }
  }
`;

const ProductI = styled.i`
  color: #454f57;
  cursor: pointer;
  font-size: 14px;
  vertical-align: middle;
  text-decoration: underline;
`;

const SocialRow = styled(Row)`
  border-top: ${({dark, theme}) =>
    dark ? `1px solid #2f2f31 ` : `1px solid ${theme.colors.border}`};
`;

const SocialIcon = styled.i`
  font-size: 40px !important;
`;

const CopyRightArea = styled.div`
  border-top: ${({dark, theme}) =>
    dark ? `1px solid #2f2f31 ` : `1px solid ${theme.colors.border}`};

  padding: 15px 0;
  p {
    color: ${({dark, theme}) =>
    dark ? theme.colors.lightShade : theme.colors.darkShade};
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      color: ${({dark, theme}) =>
    dark ? theme.colors.light : theme.colors.dark} !important;
      font-size: 16px;
      transition: 0.4s;
      padding: 0 3px;
      margin: 0 2.5px;
      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        color: ${({theme, color}) => theme.colors.secondary} !important;
      }
    }
  }
`;

const Footer = ({isDark = true}) => {
    return (
        <>
            {/* <!-- Footer section --> */}
            <Box bg="greyBg">
                <Box pt={[null, null, null, 4, 10]} px={[3, 3, 3, 3, 6]}>
                    <Row className="justify-content-center">
                        <Col xs="12" sm="10" className="mt-3 mt-lg-4">
                            <Row className="justify-content-center">
                                <Col xs="12" sm="4" lg="3">
                                    <div className="mb-5 mb-lg-4">
                                        <TitleStyled variant="card" color="dark">
                                            Company
                                        </TitleStyled>
                                        <UlStyled color="darkShade">
                                            <li>
                                                <Link to="/about-us">About Us</Link>
                                            </li>
                                            <li>
                                                <Link to="/contact-us">Contact Us</Link>
                                            </li>
                                        </UlStyled>
                                    </div>
                                </Col>
                                <Col xs="12" sm="4" lg="3">
                                    <div className="mb-5 mb-lg-4">
                                        <TitleStyled variant="card" color="dark">
                                            Products
                                        </TitleStyled>
                                        <UlStyled color="darkShade">
                                            <ul>
                                                <li>
                                                    <Link to="/flop-app">FLOP App</Link>
                                                    <a
                                                        href="https://flop.page.link/get-flop-app"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <li className="mb-3">
                                                            <img
                                                                style={{marginRight: 10}}
                                                                src={downloadPng}
                                                                alt="Download First land of poker FLOP application"
                                                            />
                                                            <ProductI>Download for free</ProductI>
                                                        </li>
                                                    </a>
                                                </li>
                                                <li>
                                                    <Link to="/flop-gto">FLOP GTO</Link>
                                                    <a
                                                        href="https://flopgto.com/signup?type=premium"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <li className="mb-3">
                                                            <img
                                                                style={{marginRight: 10}}
                                                                src={downloadPng}
                                                                alt="Get the premium package for FLOP Game Theory Optimal"
                                                            />
                                                            <ProductI>Get your premium package</ProductI>
                                                        </li>
                                                    </a>
                                                </li>
                                            </ul>
                                        </UlStyled>
                                        <UlStyled color="darkShade">
                                            <ul>
                                                <li>
                                                    <Link to="/flop-pm">FLOP PM</Link>
                                                    <a
                                                        href="https://flop-pm.firstlandofpoker.com/user-auth/sign-up"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <li className="mb-3">
                                                            <img
                                                                style={{marginRight: 10}}
                                                                src={offerPng}
                                                                alt="Flop PM Free Trial - The managing tool you need"
                                                            />
                                                        </li>
                                                    </a>
                                                </li>
                                            </ul>
                                        </UlStyled>
                                    </div>
                                </Col>
                                <Col xs="12" sm="4" lg="3">
                                    <TitleStyled variant="card" color="dark">
                                        Events
                                    </TitleStyled>
                                    <UlStyled color="darkShade">
                                        <li>
                                            <Link to="/papc">Patrik Antonius Poker Challenge</Link>
                                            <a
                                                href="#"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <li className="mb-3">
                                                    <img
                                                        style={{marginRight: 10}}
                                                        src={tournamentPng}
                                                        alt="Patrik Antonius Poker Challenge 2022"
                                                    />
                                                    <ProductI>Join Us for a full week of poker</ProductI>
                                                </li>
                                            </a>
                                        </li>
                                    </UlStyled>
                                </Col>
                                <Col xs="12" sm="4" lg="3">
                                    <div className="mb-lg-4">
                                        <TitleStyled variant="card" color="dark">
                                            <Link to="/blog">Blog</Link>
                                        </TitleStyled>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Box>
                <SocialRow className="py-3">
                    <Col>
                        <Text fontSize={14} mb={3} textAlign="center" small>
                            Follow Us
                        </Text>
                        <Row className="justify-content-center">
                            <a
                                className="mx-3 w-auto"
                                href="https://www.facebook.com/firstlandofpoker"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <SocialIcon className="icon icon-logo-facebook"/>
                            </a>
                            <a
                                className="mx-3 w-auto"
                                href="https://www.instagram.com/firstlandofpoker"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <SocialIcon className="icon icon-logo-instagram"/>
                            </a>
                            <a
                                className="mx-3 w-auto"
                                href="https://twitter.com/flop_app"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <SocialIcon className="icon icon-logo-twitter"/>
                            </a>
                        </Row>
                    </Col>
                </SocialRow>
                <CopyRightArea dark={0}>
                    <Row className="align-items-center justify-content-center">
                        <Col sm="6" className="text-center mb-2 mb-sm-0">
                            <p>
                                &copy; {new Date().getFullYear()} WeOpt, All Rights Reserved
                            </p>
                        </Col>
                    </Row>
                </CopyRightArea>
            </Box>
        </>
    );
};

export default Footer;
