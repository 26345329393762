export const menuItems = [
  {
    name: "flop-app",
    label: "menu.flop_app",
    displayLarge: false,
  },
  {
    name: "flop-pm",
    label: "menu.flop_pm",
    displayLarge: false,
  },
  {
    name: "flop-gto",
    label: "menu.flop_gto",
    displayLarge: false,
  },
  {
    name: "papc",
    label: "menu.papc",
    displayLarge: false
  },
  {
    name: "wpt",
    label: "menu.wpt",
    displayLarge: false
  },
  {
    name: "about-us",
    label: "menu.about_us",
    displayLarge: true,
  },
  // {
  //   name: "affiliates",
  //   label: "menu.affiliates",
  // displayLarge: true,
  // },
  // {
  //   name: "events",
  //   label: "menu.events",
  // displayLarge: true,
  // },
  {
    name: "blog",
    label: "menu.blog",
    displayLarge: true,
  },
  {
    name: "contact-us",
    label: "menu.contact_us",
    displayLarge: true,
  },
];
